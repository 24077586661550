<template>
  <div class="a15-index">
    <div class="animated fadeIn slow title">
      {{ title }}
    </div>
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSave">保存</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="checkMeterageList">清单</el-button>
        </el-form-item>
        <el-form-item v-if="errors.length">
          <el-button type="primary" @click="handleLocateError">下一个错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <spread
      ref="mySpread"
      v-if="headerColumns.length"
      sheet-name="变更计量申报"
      :headerColumns="headerColumns"
    >
    </spread>

    <el-drawer
      title="变更清单"
      :visible.sync="showDrawer"
      :with-header="false"
      size="1180"
      >
      <div v-if="tableHeight" class="meterage-list" :style="meterageListStyle">
        <spread
          sheet-name="变更清单"
          :headerColumns="changeDirectiveListHeaderColumns"
          :data="displayMeterageList"
          :protected="true"
        >
        </spread>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Spread from '@/components/Spread/ChangeDirectiveSpread'
import headerColumns from '@/views/ExaminationManage/ChangeDirectiveHeaderColumns'
import changeDirectiveListHeaderColumns from '@/views/ExaminationManage/ChangeDirectiveListHeaderColumns'
import meterageListService from '@/services/meterageListService'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import A15TransferModel from '@/model/A15TransferModel'
import a15Service from '@/services/a15Service'
import { mapGetters } from 'vuex'
import config from '@/config'
import 'animate.css'
import moment from 'moment'

export default {
  name: 'A15DetailIndex',
  components: {
    MySearchBar,
    Spread
  },
  data () {
    return {
      tableHeight: 0,
      showDrawer: false,
      headerColumns: [],
      changeDirectiveListHeaderColumns,
      meterageList: [],
      A15List: [],
      errors: [],
      errorIndex: 0
    }
  },
  computed: {
    title () {
      return `${this.tenderName}标段 第${this.periodName}期 编号：${this.examinationCode}`
    },
    displayMeterageList () {
      console.log(this.meterageList, 'computed')
      return this.meterageList.filter(item => item.num !== 0)
    },
    meterageListStyle () {
      return {
        height: this.tableHeight + 'px'
      }
    },
    ...mapGetters('examination', {
      examinationGuid: 'getExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      periodName: 'getPeriodName',
      examinationCode: 'getExaminationCode'
    })
  },
  methods: {
    handleLocateError () {
      this.errorIndex = (this.errorIndex + 1) % this.errors.length
      this.$refs.mySpread.showRow(this.errors[this.errorIndex].rowIndex)
    },
    checkMeterageList () {
      this.showDrawer = true
    },
    handleSave () {
      this.errors = this.$refs.mySpread.getErrors()
      if (this.errors.length === 0) {
        this._translate()
        this._pushData()
      } else {
        this.$message({
          type: 'error',
          message: '您输入的数据有误，请检查！'
        })
        this.$refs.mySpread.showRow(this.errors[0].rowIndex)
      }
    },
    handleRowDblClick (row) {
      console.log(row)
    },
    _parseErrors (data) {
      let errorMeteragetList = data
        .filter(error => error.cause === config.meterageListOverflowCode)
        .map(error => error.causeColumn)
      this.errors = []
      this.errorIndex = 0

      let excelData = this.$refs.mySpread.json()
      for (let rowIndex in excelData) {
        let row = excelData[rowIndex]
        if (row[0].value !== undefined && row[0].value !== '' && errorMeteragetList.indexOf(row[0].value) > -1) {
          let colIndex = 9
          this.errors.push({ rowIndex, colIndex })
          this.$refs.mySpread.setValidationStatus(false, parseInt(rowIndex), colIndex)
        }
      }

      if (this.errors.length) {
        // locate the first error
        this.$refs.mySpread.showRow(this.errors[0].rowIndex)
      }
    },
    _pushData () {
      a15Service.batchAdd(this.periodName, this.A15List)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '保存成功！'
            })
            this.$router.push('/examinationDetail?readonly=false')
          } else {
            this._parseErrors(res.data.data)
            this.$message({
              type: 'error',
              message: '你输入的数量存在超计！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '保存失败'
          })
        })
    },
    _translate () {
      this.A15List = []
      let excelData = this.$refs.mySpread.json()

      for (let rowIndex in excelData) {
        let row = excelData[rowIndex]
        let a15Model = new A15TransferModel({ rowNum: rowIndex, examinationGuid: this.examinationGuid, tenderGuid: this.tenderGuid })

        let hasValue = false

        for (let colIndex in row) {
          let col = row[colIndex]
          if (col.value !== undefined && col.value !== '') {
            hasValue = true
            if (this.headerColumns[colIndex].type === 'string') {
              a15Model[this.headerColumns[colIndex].key] = col.value.toString()
            } else {
              a15Model[this.headerColumns[colIndex].key] = col.value
            }
          }
        }
        if (a15Model.a15Date && a15Model.a15Date.indexOf('(') !== -1 && a15Model.a15Date.indexOf(')') !== -1) {
          let diffDays = a15Model.a15Date.substring(a15Model.a15Date.indexOf('(') + 1, a15Model.a15Date.indexOf(')'))
          let startDate = moment([1899, 11, 30]).add(parseInt(diffDays), 'days').format('YYYY-MM-DD')
          a15Model.a15Date = startDate
        }
        hasValue && this.A15List.push(a15Model)
      }
    },
    _getMeterageList () {
      meterageListService.changeDirectiveListByTenderGuid(this.tenderGuid, this.periodName)
        .then(res => {
          console.log(res.data)
          if (res.data.code === 1) {
            this.meterageList = res.data.data

            headerColumns[0].comboData = this.displayMeterageList
            this.headerColumns = headerColumns
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err.request.response)
        })
    },
    _computeHeight () {
      this.tableHeight = document.body.offsetHeight
    }
  },
  mounted () {
    this._computeHeight()
  },
  created () {
    this._getMeterageList()
  }
}
</script>

<style scoped lang="scss">
.a15-index {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr;
}
.meterage-list {
  width: 1280px;
  height: 600px;
}
.title {
  position: absolute;
  line-height: 40px;
  font-size: 14px;
  top: 65px;
  right: 165px;
  padding-right: 5px;
  color: red;
}
</style>
