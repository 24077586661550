const headerColumns = [
  {
    index: 0,
    name: '子目号',
    key: 'meterageCode',
    comboValueField: 'meterageCode',
    comboData: [],
    type: 'string',
    width: 150,
    rules: [
      {
        type: 'required'
      },
      {
        type: 'custom',
        validator (sheet, rowIndex, colIndex) {
          const myValue = sheet.getValue(rowIndex, colIndex)
          return headerColumns[0].comboData.findIndex(column => column.meterageCode === myValue) > -1
        }
      }
    ]
  },
  {
    index: 1,
    name: '变更批次号',
    key: 'directiveBatchID',
    type: 'string',
    width: 150,
    rules: [
    ]
  },
  {
    index: 2,
    name: '子目名称',
    key: 'meterageName',
    type: 'string',
    width: 150,
    rules: [
    ]
  },
  {
    index: 3,
    name: '起始桩号',
    key: 'startMileage',
    type: 'string',
    width: 150,
    rules: [
    ]
  },
  {
    index: 4,
    name: '终止桩号',
    key: 'endMileage',
    type: 'string',
    width: 150,
    rules: [
    ]
  },
  {
    index: 5,
    name: '部位',
    key: 'part',
    type: 'string',
    width: 200,
    rules: [
    ]
  },
  {
    index: 6,
    name: '图号',
    key: 'pictureNo',
    type: 'string',
    width: 160,
    rules: [
    ]
  },
  {
    index: 7,
    name: '中间交工证书号',
    key: 'certNo',
    type: 'string',
    width: 160,
    rules: [
    ]
  },
  {
    index: 8,
    name: '计算公式',
    key: 'formula',
    type: 'string',
    width: 260,
    rules: [
    ]
  },
  {
    index: 9,
    name: '数量',
    key: 'num',
    type: 'number',
    width: 100,
    rules: [
      {
        type: 'required'
      },
      {
        type: 'number'
      }
    ]
  },
  {
    index: 10,
    name: '流水号',
    key: 'manualSerialNo',
    type: 'string',
    width: 120,
    rules: [
    ]
  },
  {
    index: 11,
    name: '日期',
    key: 'a15Date',
    type: 'date',
    width: 100,
    rules: [
    ]
  }
]

export default headerColumns
