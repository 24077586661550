import auth from '@/common/auth'

export default class A15TransferModel {
  constructor (props) {
    if (props) {
      this.rowNum = props.rowNum
      this.examinationGuid = props.examinationGuid
      this.tenderGuid = props.tenderGuid
      this.meterageCode = props.meterageCode
      this.num = props.num
      this.startMileage = props.startMileage
      this.endMileage = props.endMileage
      this.part = props.part
      this.pictureNo = props.pictureNo
      this.certNo = props.certNo
      this.formula = props.formula
      this.autoSerialNo = props.autoSerialNo
      this.manualSerialNo = props.manualSerialNo
      this.createUserGuid = props.createUserGuid
    }
    this.init()
  }

  init () {
    if (!this.rowNum) this.rowNum = ''
    if (!this.examinationGuid) this.examinationGuid = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.meterageCode) this.meterageCode = ''
    if (!this.num) this.num = 0
    if (!this.startMileage) this.startMileage = ''
    if (!this.endMileage) this.endMileage = ''
    if (!this.part) this.part = ''
    if (!this.pictureNo) this.pictureNo = ''
    if (!this.certNo) this.certNo = ''
    if (!this.formula) this.formula = ''
    if (!this.autoSerialNo) this.autoSerialNo = ''
    if (!this.manualSerialNo) this.manualSerialNo = ''
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
  }
}
