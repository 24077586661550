<template>
  <div class="spread-container" ref="spreadContainer">
  </div>
</template>

<script>
import spreadMenus from '@/assets/json/spreadMenus'

export default {
  name: 'Spread',
  components: {
  },
  props: {
    sheetName: {
      type: String,
      default: 'Sheet1'
    },
    protected: {
      type: Boolean,
      default: false
    },
    headerColumns: {
      type: Array,
      default () {
        return [
        ]
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      meterageListMap: {}
    }
  },
  methods: {
    json () {
      return this.spread.getActiveSheet().toJSON().data.dataTable
    },
    showRow (rowIndex) {
      this.sheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
    },
    getErrors () {
      const errors = []
      for (let rowIndex = 0; rowIndex < this.sheet.getRowCount(); ++rowIndex) {
        if (!this._checkRow(rowIndex)) {
          errors.push({ rowIndex, colIndex: 0 })
        }
      }
      return errors
    },
    isValidated () {
      for (let rowIndex = 0; rowIndex < this.sheet.getRowCount(); ++rowIndex) {
        if (!this._checkRow(rowIndex)) {
          return false
        }
      }
      return true
    },
    _initSheet () {
      this.sheet = new GC.Spread.Sheets.Worksheet(this.sheetName)
      this.spread.addSheet(0, this.sheet)

      this.sheet.options.clipBoardOptions = 1

      this.spread.suspendPaint()

      this.sheet.setColumnCount(this.headerColumns.length, GC.Spread.Sheets.SheetArea.colHeader)
      this.sheet.setRowCount(2000)
      this.headerColumns.forEach(column => {
        this.sheet.setValue(0, column.index, column.name, GC.Spread.Sheets.SheetArea.colHeader)
      })

      if (this.data.length) {
        this._initData()
      }

      this.spread.resumePaint()

      this.sheet.options.isProtected = this.protected
    },
    _initColumnWidth () {
      this.headerColumns.forEach(column => {
        this.sheet.setColumnWidth(column.index, column.width)
      })
    },
    _initData () {
      let dataArray = []
      this.data.forEach(meterageListItem => {
        this.meterageListMap[`${meterageListItem.meterageCode}_${meterageListItem.directiveBatchID}`] = meterageListItem
        let rowData = []
        this.headerColumns.forEach(column => {
          rowData.push(meterageListItem[column.key])
        })
        dataArray.push(rowData)
      })
      this.sheet.setArray(0, 0, dataArray)
    },
    _initSpread () {
      this.spread.options.allowContextMenu = true
      this.spread.options.allowCopyPasteExcelStyle = false
      this.spread.options.newTabVisible = false
      this.spread.contextMenu.menuData = spreadMenus.filter(menu => !menu.disabled)

      this._initSheet()
      this._initColumnWidth()
      this._initEvent()

      this.sheet.getCell(-1, 10).formatter('yyyy-MM-dd')
      this.sheet.getCell(-1, 0).formatter('@')

      this.headerColumns[0].comboData.forEach(meterageListItem => {
        this.meterageListMap[`${meterageListItem.meterageCode}_${meterageListItem.directiveBatchID}`] = meterageListItem
      })
    },
    _changeMeterageName (rowIndex) {
      let meterageCode = this.sheet.getValue(rowIndex, 0)
      let directiveBatchID = this.sheet.getValue(rowIndex, 1)
      if (meterageCode && directiveBatchID) {
        this.sheet.setValue(rowIndex, 2, this.meterageListMap[`${meterageCode}_${directiveBatchID}`].meterageName)
      }
    },
    _initEvent () {
      this.sheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        console.log(sender, info)
        for (let rowIndex = info.cellRange.row; rowIndex < info.cellRange.rowCount + info.cellRange.row; ++rowIndex) {
          for (let colIndex = 0; colIndex < this.sheet.getColumnCount(); ++colIndex) {
            this._validateCell(rowIndex, colIndex)
            this._changeMeterageName(rowIndex)
          }
        }
      })
      this.sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (e, info) => {
        this._validateRow(info.row)
        this._changeMeterageName(info.row)
      })
      this.sheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        console.log(e, info)
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this._validateRow(info.fillRange.row + i)
          this._changeMeterageName(info.fillRange.row)
        }
      })
      this.sheet.bind(GC.Spread.Sheets.Events.CellClick, (sender, info) => {
        let rowIndex = info.row
        let colIndex = info.col
        let column = this.headerColumns.find(headerColumn => headerColumn.index === colIndex)
        if (column && column.comboData && column.comboData.length) {
          let cellType = new GC.Spread.Sheets.CellTypes.ComboBox()
          cellType.items(column.comboData.map(combo => combo[column.comboValueField]))
            .editable(true)

          this.sheet.getCell(rowIndex, colIndex).cellType(cellType)
        }
      })
      this.spread.commandManager().setShortcutKey('clearAndEditing', GC.Spread.Commands.Key.del, false, false, false, false)
    },
    _validateRow (rowIndex) {
      let isEmptyRow = this._isEmptyRow(rowIndex)

      for (let colIndex = 0; colIndex < this.sheet.getColumnCount(); ++colIndex) {
        if (isEmptyRow) {
          this.setValidationStatus(isEmptyRow, rowIndex, colIndex)
        } else {
          this._validateCell(rowIndex, colIndex)
        }
      }
    },
    _checkRow (rowIndex) {
      if (this._isEmptyRow(rowIndex)) {
        return true
      }
      for (let colIndex = 0; colIndex < this.sheet.getColumnCount(); ++colIndex) {
        if (!this._checkValue(rowIndex, colIndex)) {
          return false
        }
      }
      return true
    },
    _isEmptyRow (rowIndex) {
      for (let colIndex = 0; colIndex < this.sheet.getColumnCount(); ++colIndex) {
        if (this.sheet.getValue(rowIndex, colIndex)) {
          return false
        }
      }
      return true
    },
    _validateCell (rowIndex, colIndex) {
      this.setValidationStatus(this._checkValue(rowIndex, colIndex), rowIndex, colIndex)
    },
    _checkValue (rowIndex, colIndex) {
      const myValue = this.sheet.getValue(rowIndex, colIndex)
      for (let i = 0; i < this.headerColumns[colIndex].rules.length; ++i) {
        if (this.headerColumns[colIndex].rules[i].type === 'required') {
          if (myValue === undefined || myValue === null || myValue === '') {
            return false
          }
        }
        if (this.headerColumns[colIndex].rules[i].type === 'number') {
          if (isNaN(myValue)) {
            return false
          }
        }
        if (this.headerColumns[colIndex].rules[i].type === 'custom') {
          return this.headerColumns[colIndex].rules[i].validator(this.sheet, rowIndex, colIndex)
        }
      }
      return true
    },
    setValidationStatus (valid, rowIndex, colIndex) {
      if (valid) {
        this.sheet.getCell(rowIndex, colIndex).setBorder(new GC.Spread.Sheets.LineBorder('#d4d4d4', GC.Spread.Sheets.LineStyle.thin), { all: true }, 3)
        this.sheet.getCell(rowIndex, colIndex).backColor('white')
      } else {
        this.sheet.getCell(rowIndex, colIndex).setBorder(new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.hair), { all: true }, 3)
        this.sheet.getCell(rowIndex, colIndex).backColor('#ccc')
      }
    },
    _initialize () {
      this.spread = new GC.Spread.Sheets.Workbook(this.$refs.spreadContainer, { calcOnDemand: true, sheetCount: 0 })

      this._initSpread()
    }
  },
  mounted () {
    console.log(GC)
    this._initialize()
  }
}
</script>

<style scoped lang="scss">
.spread-container {
  width: 100%;
  height: 100%;
}
</style>
